import localConfig from '@/config';
import { BRIDGE_ENV } from '@/constants/common';

const isMainApp = localConfig.bridgeEnv === BRIDGE_ENV.MAINNET || localConfig.bridgeEnv === BRIDGE_ENV.TESTNET;
const showOPBanner = localConfig.showOPBanner === 'true';

export const navLinks = (): {
  href: string;
  text: string;
  show: boolean;
  disabled?: boolean;
  children?: Array<{
    href: string;
    text: string;
    show: boolean;
    disabled?: boolean;
    icon?: string;
  }>;
}[] => [
  {
    href: 'https://claim.mode.network/',
    text: 'Claim',
    show: isMainApp && !showOPBanner,
  },
  {
    href: '/early/',
    text: 'Dashboard',
    show: isMainApp,
  },
  {
    href: '/dashboard/',
    text: 'Dashboard',
    show: isMainApp && localConfig.showDashboardPage === 'true',
  },
  {
    href: '/developers/',
    text: 'Developers',
    show: isMainApp && localConfig.showDevelopersPage === 'true',
  },
  {
    href: '/',
    text: 'Governance',
    show: isMainApp,
    children: [
      {
        href: 'https://gov.mode.network/',
        text: 'Voting',
        show: true,
        icon: '/assets/icons/voting.svg',
      },
      {
        href: 'https://forum.mode.network/',
        text: 'Forum',
        show: true,
        icon: '/assets/icons/forum.svg',
      },
    ],
  },
  {
    href: '/ai/',
    text: 'AI',
    show: isMainApp && localConfig.showAIPage === 'true',
  },
  {
    href: 'https://www.mode.network/vemode',
    text: 'About',
    show: isMainApp,
  },
  {
    href: '/',
    text: 'Bridge',
    show: isMainApp,
  },
  {
    href: '/faq',
    text: 'FAQ',
    show: false,
  },
  {
    href: localConfig.bridgeEnv === BRIDGE_ENV.MAINNET_BRIDGE ? '/' : localConfig.bridgeMainnettUrl,
    text: 'Mainnet',
    show: !isMainApp,
  },
  {
    href: localConfig.bridgeEnv === BRIDGE_ENV.TESTNET_BRIDGE ? '/' : localConfig.bridgeTestnetUrl,
    text: 'Testnet',
    show: !isMainApp,
  },
];
