'use client';

import { ChangeEvent, useCallback, useEffect } from 'react';
import { Locale, useLocalizationContext } from '@/app/context/LocalizationContext';

const LanguageSwitcher = () => {
  const localeFromStorage = localStorage.getItem('locale') as Locale | null;

  const { locale, setLocale } = useLocalizationContext();
  useEffect(() => {
    if (!localeFromStorage) {
      localStorage.setItem('locale', Locale.enUS);
      setLocale(Locale.enUS);
    }
  }, [localeFromStorage, setLocale]);

  const handleLanguageChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      localStorage.setItem('locale', e.target.value);
      setLocale(e.target.value as Locale);
    },
    [setLocale]
  );

  return (
    <select
      id="languages"
      value={locale}
      onChange={handleLanguageChange}
      className="focus-visible:outline-none block text-neutral-100 border border-neutral-900 bg-neutral-black
            uppercase font-medium rounded-lg text-xs p-2 md:px-4"
    >
      <option value={Locale.enUS}>Eng</option>
      <option value={Locale.ukUA}>Ukr</option>
    </select>
  );
};

export default LanguageSwitcher;
