'use client';

import { useState, useEffect, ReactNode } from 'react';
import { RainbowKitProvider, darkTheme, getDefaultConfig, Chain } from '@rainbow-me/rainbowkit';
import {
  argentWallet,
  trustWallet,
  okxWallet,
  tokenPocketWallet,
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  metaMaskWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { WagmiProvider } from 'wagmi';
import { mainnet } from 'wagmi/chains';

import { WalletIcon } from '@/components';
import { BridgeProvider } from './BridgeProvider';
import { mainnetUpdated, mode, modeTestnet, sepoliaUpdated } from './mode';
import localConfig from '@/config';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/config/query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocationContextProvider } from '@/app/context/LocationContext';
import { LocalizationContextProvider } from '@/app/context/LocalizationContext';
import { getActiveRpc } from '@/utils/wallet';
import { RPC_OVERRIDE_KEY_L1, RPC_OVERRIDE_KEY_L2 } from '@/constants/common';
import { AVAILABLE_RPC } from '@/components/RpcSelector';
import { GuideProvider } from '@/app/context/GuideProvider';

const modeBlastRpc = AVAILABLE_RPC[mode.id].find((item) => item.label === 'BlastAPI');
const mainnetBlastRpc = AVAILABLE_RPC[mainnet.id].find((item) => item.label === 'BlastAPI');

const modeTestnetCustom = {
  ...modeTestnet,
  iconUrl: '/assets/icons/mode_yellow.svg',
};

const modeMainnetCustom = {
  ...mode,
  iconUrl: '/assets/icons/mode_yellow.svg',
  rpcUrls: getActiveRpc(
    RPC_OVERRIDE_KEY_L2,
    modeBlastRpc
      ? {
          default: {
            http: modeBlastRpc.value,
          },
          public: {
            http: modeBlastRpc.value,
          },
        }
      : mode.rpcUrls
  ),
};

const mainnetCustom = {
  ...mainnet,
  rpcUrls: getActiveRpc(
    RPC_OVERRIDE_KEY_L1,
    mainnetBlastRpc
      ? {
          default: {
            http: mainnetBlastRpc.value,
          },
          public: {
            http: mainnetBlastRpc.value,
          },
        }
      : mainnetUpdated.rpcUrls
  ),
};

const sepoliaCustom = {
  ...sepoliaUpdated,
  rpcUrls: getActiveRpc(RPC_OVERRIDE_KEY_L1, sepoliaUpdated.rpcUrls),
};

const chains: [Chain, ...Chain[]] = (() => {
  if (localConfig.l1ChainId === '1') {
    return [modeMainnetCustom, mainnetCustom] as [Chain, ...Chain[]];
  } else {
    return [modeTestnetCustom, sepoliaCustom] as [Chain, ...Chain[]];
  }
})();

const projectId = '8acd77a327d10ec6a96b57bd695419d8';

const demoAppInfo = {
  appName: 'Mode app',
};

const wagmiConfig = getDefaultConfig({
  appName: 'Mode app',
  projectId,
  chains,
  wallets: [
    {
      groupName: 'Popular',
      wallets: [rainbowWallet, walletConnectWallet, coinbaseWallet, metaMaskWallet],
    },
    {
      groupName: 'Other',
      wallets: [okxWallet, argentWallet, trustWallet, tokenPocketWallet],
    },
  ],
});

export function Providers({ children }: { children: ReactNode }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          appInfo={demoAppInfo}
          locale="en"
          theme={darkTheme({})}
          avatar={({ address, size }) => <WalletIcon seed={address} size={size} />}
        >
          <LocalizationContextProvider>
            <LocationContextProvider>
              <GuideProvider>
                <BridgeProvider>{mounted && children}</BridgeProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </GuideProvider>
            </LocationContextProvider>
          </LocalizationContextProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
