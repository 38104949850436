'use client';

import { RpcSelectorDropdown } from '@/components/RpcSelector/RpcSelectorDropdown';
import localConfig from '@/config';
import { MODE_ENV, RPC_OVERRIDE_KEY, RPC_OVERRIDE_KEY_L1, RPC_OVERRIDE_KEY_L2 } from '@/constants/common';
import { mode } from '@/mode';
import { useCallback, useMemo } from 'react';
import { useAsync } from 'react-use';
import { getGasPrice } from 'viem/actions';
import { useAccount, useChainId, usePublicClient } from 'wagmi';
import { mainnet } from 'wagmi/chains';

type RpcOption = {
  label: string;
  value: string[];
};

export const AVAILABLE_RPC: Record<string, RpcOption[]> = {
  34443: [
    {
      label: 'Official Mode Mainnet',
      value: ['https://mainnet.mode.network'],
    },
  ],
  1: [
    {
      label: 'Alchemy',
      value: ['https://eth-mainnet.g.alchemy.com/v2/RMfw-hu9MEpllxs59-ls_JP88YskBSnE'],
    },
    {
      label: 'Drpc',
      value: ['https://eth.drpc.org'],
    },
  ],
};

function getActiveRpcLabel(chainId: number) {
  let key: string;

  switch (chainId) {
    case mainnet.id: {
      key = RPC_OVERRIDE_KEY_L1;
      break;
    }
    case mode.id: {
      key = RPC_OVERRIDE_KEY_L2;
      break;
    }
    default: {
      key = RPC_OVERRIDE_KEY;
    }
  }

  try {
    const override = localStorage.getItem(key);

    if (override) {
      const parsed = JSON.parse(override) as RpcOption;

      return parsed?.label || 'Default';
    }

    return 'Default Rpc';
  } catch (e) {
    console.error(e);
    return 'Default Rpc';
  }
}

export const RpcSelector = () => {
  const { address } = useAccount();
  const chainId = useChainId();
  const activeRpcLabel = getActiveRpcLabel(chainId);

  const OPTIONS = useMemo(() => {
    return AVAILABLE_RPC[chainId];
  }, [chainId]);

  const handleRpcSelect = useCallback(
    (value: RpcOption) => {
      let key: string;

      switch (chainId) {
        case mainnet.id: {
          key = RPC_OVERRIDE_KEY_L1;
          break;
        }
        case mode.id: {
          key = RPC_OVERRIDE_KEY_L2;
          break;
        }
        default: {
          key = RPC_OVERRIDE_KEY;
        }
      }

      try {
        localStorage.setItem(key, JSON.stringify(value));

        window.location.reload();
      } catch (e) {
        console.error(e);
      }
    },
    [chainId]
  );

  const client = usePublicClient();

  const { value, loading } = useAsync(async () => {
    if (!client) return;

    return Promise.all(
      OPTIONS.map(async (rpcOption) => {
        const start = performance.now();

        await getGasPrice(client);

        const end = performance.now();

        const latency = end - start;

        return { ...rpcOption, latency };
      })
    );
  }, [client, chainId]);

  const activeLatency = value?.find((item) => item.label === activeRpcLabel)?.latency;

  if (!address || loading || !value || localConfig.modeEnv === MODE_ENV.TESTNET) {
    return null;
  }

  return (
    <RpcSelectorDropdown
      options={value}
      selectedLabel={activeRpcLabel}
      selectedLatency={activeLatency}
      onSelect={(v) => {
        handleRpcSelect(v);
      }}
    />
  );
};
