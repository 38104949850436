import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"IBM_Plex_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"MONO\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@rainbow-me/rainbowkit/dist/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/animate.css/animate.css");
;
import(/* webpackMode: "eager", webpackExports: ["SkeletonTheme"] */ "/vercel/path0/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/vercel/path0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-toastify/dist/ReactToastify.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageview","PHProvider"] */ "/vercel/path0/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/src/providers.tsx");
