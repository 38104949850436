'use client';

import { RpcSelector } from '@/components/RpcSelector';
import Image from 'next/image';

const Footer = () => {
  return (
    <footer className="border-neutral-900 border-t-[1px] px-4 md:px-20 h-[80px] flex items-center justify-between bg-neutral-black">
      <Image src={'/mode_logo.svg'} alt="logo" width={65} height={20} priority />
      <RpcSelector />
    </footer>
  );
};

export default Footer;
