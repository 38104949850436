'use client';

import { ChevronIcon } from '@/components/icons';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

export function DropdownNavMenu({
  items,
  name,
  disabled,
  isMobile,
}: {
  name: string;
  isMobile?: boolean;
  disabled?: boolean;
  items: Array<{
    href: string;
    text: string;
    show: boolean;
    disabled?: boolean;
    icon?: string;
  }>;
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside([dropdownRef], handleClickOutside);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  function handleClickOutside(event: MouseEvent | TouchEvent | FocusEvent) {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      dropdownRef.current &&
      !dropdownRef?.current?.parentElement?.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  }

  return (
    <div ref={dropdownRef} className="relative">
      <button
        onClick={toggleDropdown}
        className={clsx(
          disabled && 'pointer-events-none text-neutral-600',
          !isMobile && 'hover:bg-primary-500/8 hover:border-primary-500/8',
          'py-2 px-2 xl:px-4',
          'text-neutral-100 font-medium border border-transparent rounded-lg uppercase text-sm xl:text-base flex gap-2 items-start'
        )}
      >
        {name}
        <ChevronIcon className="xl:mt-0 mt-[-2px]" />
      </button>
      {isDropdownOpen && (
        <nav
          className={clsx(
            !isMobile
              ? 'w-full absolute right-0 top-[45px] origin-top-right bg-[#060606] border rounded-lg border-neutral-900 p-4'
              : 'w-full p-4 pl-8 flex flex-col gap-4 pt-0'
          )}
        >
          <div className="flex flex-col gap-4">
            {items.map((item) => {
              return (
                <Link
                  key={`${item.text}-${item.href}`}
                  href={item.href}
                  className={'flex gap-3 items-center text-xs text-neutral-100 uppercase font-medium'}
                >
                  {item.icon && <Image src={item.icon} alt="logo" width={20} height={20} />}
                  {item.text}
                </Link>
              );
            })}
          </div>
        </nav>
      )}
    </div>
  );
}
