'use client';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import merge from 'lodash.merge';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { useOnClickOutside } from 'usehooks-ts';

type Option<T> = {
  icon?: string;
  label: string;
  value: T;
  latency?: number;
};

interface DropdownProps<T> {
  options: Option<T>[];
  onToggle?: (newState: boolean) => void;
  disabled?: boolean;
  selectedLabel: string;
  selectedLatency?: number;
  onSelect: (val: Option<T>) => void;
  className?: string;
}

export const RpcSelectorDropdown = <T,>({
  options,
  onToggle,
  disabled,
  selectedLabel,
  selectedLatency,
  onSelect,
  className,
}: DropdownProps<T>) => {
  const [open, setOpen] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLElement>();
  const [referenceElement, setReferenceElement] = useState<HTMLElement>();

  useOnClickOutside(
    [
      { current: popperElement } as React.RefObject<HTMLElement>,
      { current: referenceElement } as React.RefObject<HTMLElement>,
    ],
    () => setOpen(false)
  );

  const opts = merge(
    {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
    options
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, opts);

  const handleOptionClick = (option: Option<T>) => {
    onSelect(option);
    setOpen(false);
  };

  useEffect(() => {
    if (onToggle) onToggle(open);
  }, [open, onToggle]);

  return (
    <div
      className={clsx(className, 'inline-block w-full relative text-neutral-500 max-w-[240px]')}
      style={{
        position: 'relative',
        zIndex: open ? 21 : 20,
      }}
    >
      <div>
        <button
          ref={setReferenceElement as React.LegacyRef<HTMLButtonElement>}
          disabled={disabled}
          onClick={() => setOpen(!open)}
          type="button"
          className={clsx(
            'flex items-center w-full px-1 sm:px-2 py-1 font-medium border border-neutral-600 bg-neutral-900 rounded-lg'
          )}
          id="options-menu"
          aria-haspopup="listbox"
          style={{
            minWidth: '120px',
          }}
        >
          <div className="flex flex-col items-start gap-1 w-full">
            <div className="flex w-full">
              <span className="text-neutral-100 mx-1 font-normal whitespace-nowrap overflow-hidden overflow-ellipsis text-[12px]">
                {selectedLabel}
              </span>
              <span className="text-neutral-100 whitespace-nowrap overflow-hidden overflow-ellipsis text-[12px] ml-auto">
                {selectedLatency ? `${selectedLatency.toFixed()} ms` : null}
              </span>
              {!disabled && (
                <Image
                  src={'/down_arrow.svg'}
                  alt="svg"
                  width={18}
                  height={18}
                  className={clsx('ml-auto', {
                    'rotate-180': open,
                  })}
                />
              )}
            </div>
          </div>
        </button>
      </div>

      <AnimatePresence>
        {open && (
          <div
            className="w-full"
            ref={setPopperElement as React.LegacyRef<HTMLDivElement>}
            style={styles.popper}
            {...attributes.popper}
          >
            <motion.ul
              initial={{ opacity: 0, transform: 'translateY(20px)' }}
              animate={{ opacity: 1, transform: 'translateY(0px)' }}
              exit={{ opacity: 0 }}
              role="listbox"
              aria-labelledby="options-menu"
              aria-activedescendant="selected-option"
              className="py-1 w-full sm:w-auto rounded-lg border border-neutral-600 bg-neutral-900"
            >
              {options.map((option, ix) => (
                <li
                  key={`${option.label}-${ix}`}
                  onClick={() => handleOptionClick(option)}
                  className="cursor-pointer select-none relative px-2 py-2 flex items-center"
                >
                  <div className="flex items-center space-x-2 w-full">
                    {option.icon && <Image src={option.icon} alt="svg" width={24} height={24} className="ml-auto" />}
                    <span className="text-neutral-100 whitespace-nowrap overflow-hidden overflow-ellipsis text-[12px]">
                      {option.label}
                    </span>
                    <span className="text-neutral-100 whitespace-nowrap overflow-hidden overflow-ellipsis text-[12px] ml-auto">
                      {option.latency ? `${option.latency?.toFixed()} ms` : null}
                    </span>
                  </div>
                </li>
              ))}
            </motion.ul>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};
